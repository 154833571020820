import styled from 'styled-components';

import { Link, Paragraph } from '@/UI';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ImgBox = styled.div`
  margin-bottom: 20px !important;
  max-width: 250px;
  width: 100%;

  span {
    width: 100% !important;
    height: auto !important;
  }
`;

export const Title = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.grays80};
  text-align: center;
`;

export const CompanyUrl = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  margin-bottom: 20px;
`;

export const CountryUrl = styled(Link)`
  color: ${({ theme }) => theme.palette.grays80};
`;

export const CountryBox = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  min-width: 90px;
`;

export const ImgCountry = styled.img`
  width: 30px;
`;
