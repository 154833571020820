import Image from 'next/image';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'react-i18next';

import aproLogo from '@/assets/AproLogo.svg';
import { GlobalContentLayout } from '@/components/Layouts';
import { brandTheme } from '@/UI/Theme/brandTheme';
import { Modal, Theme } from '@/UI';
import { useCheckWebAddressCountries } from '@/services/company';
import * as St from './Home.styles';
import { useIframeRouter } from '@/hooks/useIframeRouter';

const COUNTRIES = {
  ar: '/assets/flag-ar.svg',
  bo: '/assets/flag-bo.svg',
  cl: '/assets/flag-cl.svg',
  co: '/assets/flag-co.svg',
  cr: '/assets/flag-cr.svg',
  cw: '/assets/flag-cw.svg',
  do: '/assets/flag-do.svg',
  ec: '/assets/flag-ec.svg',
  es: '/assets/flag-es.svg',
  gt: '/assets/flag-gt.svg',
  it: '/assets/flag-it.svg',
  mx: '/assets/flag-mx.svg',
  pa: '/assets/flag-pa.svg',
  pe: '/assets/flag-pe.svg',
  py: '/assets/flag-py.svg',
  us: '/assets/flag-us.svg',
  uy: '/assets/flag-uy.svg',
  ve: '/assets/flag-ve.svg',
};

const Home = ({ companySlug, host }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useCheckWebAddressCountries(companySlug);
  const { routerPush } = useIframeRouter();

  if (data && data.length === 0) {
    document.location.assign('https://agendapro.com/');
    return null;
  }

  if (data && data.length === 1) {
    routerPush(data[0].url);

    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <Theme brandTheme={brandTheme}>
      <GlobalContentLayout>
        <Modal>
          <St.Container>
            <St.ImgBox>
              <Image height={64} loader={() => '/AproLogo.svg'} src={aproLogo} width={174} />
            </St.ImgBox>
            <St.Title size="subHeadline">{t('SCHEDULE_IN')}</St.Title>
            <St.CompanyUrl size="subHeadline">{host}</St.CompanyUrl>
            {data?.map(({ url, name, countryCode }) => (
              <St.CountryBox key={countryCode}>
                <St.CountryUrl size="mdSmall" href={url} isUnderlined>
                  {name}
                </St.CountryUrl>
                {COUNTRIES[countryCode] && <St.ImgCountry src={COUNTRIES[countryCode]} />}
              </St.CountryBox>
            ))}
          </St.Container>
        </Modal>
      </GlobalContentLayout>
    </Theme>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { host } = ctx.req.headers;
  const companySlug = host?.split('.')[0];

  return {
    props: {
      companySlug,
      host,
    },
  };
};

export default Home;
